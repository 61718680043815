@font-face {
  font-family: "Futura Std";
  src: url("../fonts/FuturaBT-Book.woff2") format("woff2"),
    url("../fonts/FuturaStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
.web {
  font-size: 15px;
  line-height: 20px;
  font-family: "Futura Std" !important;
  scroll-behavior: smooth !important;
  color: #231f20;
}

.landing-page {
  background-color: #fff;
  font-family: "Futura Std" !important;
  font-weight: 500 !important;
}

/* Start General Css  */
.web a {
  text-decoration: none;
}
h1 {
  font-size: 33px !important;
}
.web h1,
.web h2,
.web h3,
.web h4 {
  font-family: "Futura Std" !important;
}

.event-description,
.event-description p,
.event-description h2,
.event-description h1 {
  color: #000 !important;
}
.banner-description h1,
.banner-description h2 {
  color: #fff !important;
}

.banner-black-description h1,
.banner-black-description h2 {
  color: #000 !important;
}
.web_ready-section h2,
.web_ready-section h1,
.web_ready-section p {
  color: #000 !important;
}
.web_life-wrapper .web h1,
.web_success-section h1,
.question-heading h1 {
  font-size: 33px !important;
}
.proposetry-image p,
.proposetry-image h1 {
  color: #000;
}

.web_success-section p {
  margin: 20px 12px 0px;
}

.connected-links a,
.connected-links a:hover {
  text-decoration: none;
  color: #000;
}

.web_life-wrapper-text h2,
.web_life-wrapper-text p {
  color: #000 !important;
}

.web h2 {
  font-size: 32px;
}

/* End General Css */

/* start header */

.top-web-header {
  background-color: #c19d5f;
  color: #000;
}

.top-web-header .join-event-text a {
  color: #000;
}

.top-web-header .header-social-links ul {
  list-style-type: none;
  margin-bottom: 0;
}

.top-web-header .header-social-links ul li {
  display: inline-block;
}

.top-web-header .header-social-links ul li a {
  color: #000;
  text-decoration: none;
}

.top-web-header .header-social-links ul li .fa {
  font-weight: 400;
  font-size: 16px;
}

.web_header {
  background: #000;
  margin-top: 50px;
}

.web_header .navbar {
  padding: 24px 0px;
}

.web_header img {
  width: 150px;
}

.web_header .navbar .nav-item .nav-link {
  color: #c19d5f;
  padding: 0px 15px 0px;
  cursor: pointer;
}

.web_header .navbar button {
  background: transparent;
  color: #c19d5f;
  border: none;
  margin: 0 15px;
}

.web_header .navbar .navbar-btn button {
  border-radius: 3px;
  padding: 3px 10px;
  border: 1px solid #c19d5f;
  margin-left: 15px;
}

.web_header .navbar .navbar-btn button:hover {
  background: #c19d5f;
  color: #000000;
}

.form-section-heading h2 {
  color: #000 !important;
  font-size: 22px !important;
}
.payment-form-page .wb_time_wrapper,
.payment-form-page .web_form-wrapper {
  padding-top: 20px;
}

.landing-page .card {
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
}
.landing-page input {
  color: #000 !important;
}

.starting-point h2,
.starting-point h1,
.starting-point p,
.web_about_wrapper p,
.web_about_wrapper h2,
.web_about_wrapper h3,
.web_success-section h1,
.starting-point h3 {
  color: #000 !important;
}
.web_about_wrapper h3 {
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  color: #000;
}

.web_about_wrapper p {
  font-size: 16px;
  /* margin-top: 15px; */
  margin-bottom: 0px;
}

.web_about_wrapper .category-title p {
  font-weight: 600;
}

.web_about_wrapper {
  padding-top: 96px;
}
.vimeo-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.web_awareness_wrapper {
  padding: 70px 0px 96px 0px;
}

.web_awareness_wrapper h2 {
  margin-bottom: 40px;
  text-align: center;
}

.web_awareness_wrapper .wb_book_btn {
  margin-top: 40px;
  text-align: center;
}
.web_awareness_wrapper .wb_book_btn button {
  padding: 8px 24px;
  background: #000;
  color: #c19d5f;
  border-radius: 3px;
  border: none;
}
.web_awareness_wrapper p {
  font-size: 18px;
  line-height: 25px;
  color: #000;
}
.wb_video-wrapper {
  padding-top: 70px;
}

/* start dropdown  */
.web_header .dropdown-menu,
.web_footer .dropdown-menu {
  background: rgba(0, 0, 0, 0.6);
}
.web_header .dropdown-item:active,
.web_header .dropdown-item:hover,
.web_footer .dropdown-item:active,
.web_footer .dropdown-item:hover {
  color: #fff;
  background: transparent;
}
.web_header .dropdown-item,
.web_footer .dropdown-item {
  color: #c19d5f;
  padding: 13px;
  cursor: pointer;
}

.web_header .dropdown-toggle::after,
.web_footer .dropdown-toggle::after {
  display: none;
}
/* end dropdaown  */

.web_included_wrapper h1,
.web_included_wrapper h3,
.web_included_wrapper h2,
.web_included_wrapper p,
.web_included_wrapper hr {
  color: #000 !important;
}

.web_included_wrapper h3 {
  font-size: 17px;
}

.web_included_wrapper h2 {
  font-size: 22px;
}

.success_shop_section_text p {
  margin-top: 1.5rem;
}

@media (max-width: 912px) {
  .web_header {
    margin-top: 73px;
  }
}

@media (max-width: 820px) {
  body {
    overflow-x: hidden;
  }
  .web_header .navbar button {
    margin: 0;
    padding: 10px 0px 0px;
  }
  .web_header .navbar .nav-item .nav-link {
    padding: 10px 0px 0px;
  }

  .web_header .navbar .navbar-btn button {
    margin-top: 10px;
    margin-left: 0px;
  }
}

/* End Header  */
/* general hero section css */
.web_hero-section h1 {
  font-size: 40px !important;
}

.web_hero-section {
  padding: 150px 0px;
  color: #fff !important;
  background-color: #000 !important;
}

.web-margin-after-header {
  margin-top: 160px;
}

.web_hero-section h2 {
  font-size: 16px;
  margin-bottom: 40px;
}

.learn-btn button {
  background: #c19d5f;
  color: #000;
  border: none;
  padding: 7px 24px;
  margin-top: 29px;
  border-radius: 5px;
}
.wb_para-style {
  text-align: center !important;
}

/* @media (max-width: 1024px) {
  .web_hero-section {
    margin-top: -3px;
  }
} */

@media (max-width: 540px) {
  .web_hero-section {
    padding: 74px 0px;
  }

  .web_hero-section h1 {
    font-size: 29px !important;
  }
  .top-web-header .header-social-links {
    margin-top: 16px;
  }
  /* .web-margin-after-header {
    margin-top: 340px;
  } */
}

/* general hero section css */
/* start home page  */
.web_ready-section {
  margin-top: 96px;
  text-align: center;
  font-size: 18px;
}
.program-plan-box {
  position: relative;
}

.new_plan_short_description p {
  font-size: 20px !important;
}

.program-plan-button {
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-left: 20px;
}
.thank-you-page .web_ready-section {
  margin-bottom: 96px;
}

.web_ready-section p {
  margin-top: 15px;
  margin-bottom: 0px;
}

.web_stop-btn,
.web_money-btn {
  margin-top: 50px;
  font-size: 15px;
}

.web_stop-btn button {
  border: none;
  background: #000;
  color: #c19d5f;
  padding: 5px 24px;
  border-radius: 3px;
}

@media (max-width: 820px) {
  .web_ready-section {
    margin-top: 50px;
  }

  .web_stop-btn {
    margin-top: 30px;
  }
}

/* start program section  */
.web_program-wrapper {
  margin-top: 96px;
  background: #000;
  padding: 96px 0px;
}

.wb_life_changes {
  background: #000;
  padding: 96px 0px;
}

.web_program-wrapper .back-white {
  position: relative;
  background: #fff;
  height: 100%;
  padding: 20px;
  color: #fff;
}
.web_program-wrapper .back-white .web-program-boxes {
  margin-bottom: 35px;
}

.web_program-wrapper .back-white .learn-btn {
  position: absolute;
  bottom: 20px;
}

.wb_life_changes {
  color: #c19d5f;
}
.wb_life_changes h3 {
  margin-bottom: 14px;
}
.wb_life_changes .web_stop-btn button {
  color: #000;
  background: #c19d5f;
}

.web_program-wrapper .back-white h2 {
  font-size: 16px;
  margin-top: 20px;
  color: #000;
}
.web_program-wrapper .back-white p {
  color: #000;
}

.meet-section h2,
.meet-section h3,
.meet-section p {
  color: #000 !important;
}

.web_program-wrapper .back-white a {
  color: #000;
  text-decoration: underline;
  font-size: 15px;
}

.web_program-wrapper p {
  font-size: 13px;
}

.paymet-form-password {
  position: relative;
}
.paymet-form-password-icon {
  position: absolute;
  right: 22px;
  top: 13px;
  color: #000;
}
/* full screen image */
.empress-full-image {
  margin-top: 96px;
}
/* feature-item */
.item_feature-wrapper-cards {
  background: #000;
  padding: 96px 0px;
  color: #c19d5f;
}
.item_feature-wrapper {
  background: #000;
  padding-bottom: 96px;
  color: #c19d5f;
  overflow-x: hidden;
}
.feature-item {
  margin-top: 0px !important;
}
.feature-item-text {
  padding-top: 10rem;
}
.feature_read-btn {
  margin-top: 50px !important;
}

.feature_read-btn button {
  border: none;
  background: #c19d5f;
  color: #000;
  padding: 7px 24px;
  border-radius: 3px;
}
.feature-card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
.padding-0 {
  padding-right: 0;
  padding-left: 0;
}
@media (max-width: 1025px) {
  .item_feature-wrapper {
    background: #000;
    padding: 40px 0px;
    color: #c19d5f;
  }
  .feature-item-text {
    padding-top: 5rem;
  }
}
@media (max-width: 840px) {
  .feature-item-text {
    padding-top: 0rem;
    margin-bottom: 50px;
  }
  .web_program-wrapper,
  .wb_life_changes {
    padding: 50px 0px;
  }
}

@media (max-width: 540px) {
  .web_program-wrapper,
  .wb_life_changes {
    padding: 30px 0px;
  }
}

/* end program section  */
/* start founder section  */
.web_founder-wrapper {
  margin-top: 96px;
  text-align: center;
}

.web_read-btn {
  margin-top: 50px !important;
}
.carousel-inner .active {
  background-color: transparent !important;
}
.web_read-btn button {
  border: none;
  background: #000;
  color: #c19d5f;
  padding: 7px 24px;
  border-radius: 3px;
}

.web_founder-wrapperh3 {
  font-size: 17px;
  margin-bottom: 20px;
}

.web_founder-wrapper.px-50 {
  padding: 0px 50px;
}

@media (max-width: 820px) {
  .item_feature-wrapper {
    background: #000;
    padding: 50px 0px;
    color: #c19d5f;
  }
  .web_founder-wrapper {
    margin-top: 50px;
  }

  .web_founder-wrapper.px-50 {
    padding: 0px 12px;
  }
}

/* end founder section  */
/* start life wrapper  */
.web_life-wrapper,
.wb_goals-wrapper {
  margin-top: 96px;
  background: #eaeaea;
  padding: 96px 0px;
}
.web_included_wrapper {
  background: #eaeaea;
  padding: 96px 0px;
}
.wb_goals-wrapper ul {
  padding-left: 0;
}
.wb_goals-wrapper ul li {
  display: flex;
  color: #000;
}

.web_life-wrapper h2 {
  margin-bottom: 10px;
}
.wb_goals-wrapper h1,
.web_life-wrapper h1 {
  color: #000;
}
.wb_goals-wrapper ul li p {
  padding-left: 15px;
  margin-bottom: 18px;
}
.web_life-wrapper .mt-60 {
  margin-top: 60px;
}

.web_border-div {
  border-top: 1px solid #000;
  margin-bottom: 20px;
}

.web ul {
  list-style: none;
  padding: 0px;
}

.web_life-wrapper ul {
  padding-left: 0px !important;
}
.web_life-wrapper li {
  background: url(../images/list-icon.png) no-repeat left 5px;
  padding-left: 58px;
  margin-top: 14px;
  line-height: 26px;
  font-weight: 400;
  list-style-type: none;
  color: #000;
}
.feeling-stuck h2,
.feeling-stuck p {
  color: #000 !important;
}
@media (max-width: 820px) {
  .web_life-wrapper,
  .wb_goals-wrapper,
  .web_included_wrapper {
    margin-top: 50px;
    padding: 50px 0px;
  }
}

@media (max-width: 540px) {
  .web_life-wrapper .mt-60,
  .wb_goals-wrapper,
  .web_included_wrapper {
    margin-top: 30px;
  }
}

/* end life section  */
/* start stck section  */
.web_stuck-wrapper {
  margin-top: 96px;
  text-align: center;
}

.web_stuck-wrapper h2 {
  margin-bottom: 10px;
}

.web_stuck-wrapper p {
  margin-bottom: 0px;
  font-weight: 600;
}

@media (max-width: 820px) {
  .web_stuck-wrapper {
    margin-top: 50px;
  }
}

/* end home page  */

/* start podcast page  */
.web_success-section,
.wb_time_wrapper {
  padding-top: 96px;
}

.web-apply-for-section {
  margin-bottom: 96px;
}

.web_success-section h1 {
  padding: 0px 37px;
  margin-bottom: 0px;
}

.web_mt-96 {
  margin-top: 96px !important;
}

.web_episode-btn {
  margin-bottom: 96px;
}

.fa-angle-right {
  font-size: 20px;
}

.web_success-section a {
  color: #231f20;
  text-decoration: none;
}

.web_success-section .web_episode-btn {
  margin-top: 96px;
  text-align: center;
}

.web_success-section button {
  color: #231f20;
  text-decoration: none;
  background-color: transparent;
  border: none;
}

.brand-log-slider .slick-track {
  display: flex;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}
.web_about_wrapper .wb_money_border_div ul {
  padding-left: 40px;
}

.web_about_wrapper .wb_money_border_div ul li {
  font-size: 18px;
  margin-top: 18px;
  color: #231f20;
}

.web_about_wrapper .wb_money_border_div .form-control {
  padding: 13px 21px;
  border: 1px solid #000;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 3%);
  border-radius: 5px;
}

.web_form-wrapper .form-control,
.web_slider .form-control {
  color: #231f20 !important;
}

.wb_money_border_div .wb_book_btn {
  margin-top: 40px;
  text-align: center;
}

.wb_money_border_div .wb_book_btn button {
  padding: 8px 24px;
  background: #000;
  color: #c19d5f;
  border-radius: 3px;
  border: none;
}
.podcast-form h2 {
  color: #231f20 !important;
}

.wb_border-tb {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin: 0px 5px !important;
  padding: 10px 0px !important;
}

.wb_border-tb .link-share-icon {
  cursor: pointer;
}

.wb_border-tb svg circle {
  fill: transparent !important;
}
.wb_border-tb svg path {
  fill: #000 !important;
}
.wb_border-tb ul {
  display: flex;
  margin-bottom: 0px;
  list-style-type: none;
  padding-left: 0px !important;
}
.wb_border-tb ul li {
  margin-top: 0 !important;
}

.wb_border-tb .fa-spotify,
.fa-youtube,
.fa-instagram,
.fa-facebook-square {
  font-size: 23px;
}

.fa-music {
  border: 1px solid #000;
  border-radius: 50px;
  padding: 3px;
}

.wb_money_border_div span {
  font-size: 1;
}

.wb_money_border_div hr {
  margin: 20px 0px;
  border: 1px solid #595053;
}

.wb_money_border_div .text-muted p {
  color: #6c757d !important;
}

.web_awareness_wrapper .wb_book_btn {
  margin-top: 40px;
  text-align: center;
}

.web_awareness_wrapper .wb_book_btn button {
  padding: 8px 24px;
  background: #000;
  color: #c19d5f;
  border-radius: 3px;
  border: none;
}

@media (max-width: 768px) {
  .feature-item-text {
    padding-top: 2rem;
    margin-bottom: 50px;
  }
  .web_success-section,
  .wb_time_wrapper {
    padding-top: 54px;
  }

  .web_success-section h1 {
    padding: 0px 0px;
  }

  .web_success-section p {
    margin: 20px 0px 0px;
    font-size: 20px;
  }

  .web_mt-96,
  .web_success-section .web_episode-btn {
    margin-top: 53px;
  }
}

@media (max-width: 425px) {
  .web_success-section,
  .wb_time_wrapper {
    padding-top: 40px;
  }

  .web_mt-96 {
    margin-top: 40px;
  }

  .web_success-section p {
    font-size: 16px;
  }
}

.web_mt-40 {
  margin-top: 40px !important;
}
.web_mb-40 {
  margin-bottom: 40px !important;
}

.web_success-section .web_episode-btn {
  margin-top: 96px;
}

/* end  padcast page  */

/* / padcast 2 css start /  */
.web_about_wrapper a {
  color: #231f20 !important;
  font-size: 18px;
}

.web_about_wrapper .underline-div::after {
  background: #6b6265;
  content: "";
  display: block;
  width: 70px;
  height: 1px;
  margin: 8px 0px;
}

.wb_money_border_div {
  border: 1px solid #6b6265;
  padding: 80px;
}

.web_about_wrapper .wb_money_border_div p {
  font-size: 18px;
  margin-top: 18px;
}

.web_about_wrapper .wb_inner_border {
  border: 1px solid #595053;
  padding: 10px;
  color: #595053;
  align-items: center;
}

.web_about_wrapper .wb_inner_border,
.web_about_wrapper .wb_inner_border img {
  border-radius: 5px;
}
.podcast-detail a {
  text-decoration: none;
}
.web_episode-btn,
.wb_mb-96 {
  margin-bottom: 96px;
}

.web_about_wrapper .wb_money_border_div h3 {
  font-size: 24px;
  color: #6b6265;
  margin-bottom: 25px;
}

.web_about_wrapper .wb_money_border_div span {
  font-size: 14px;
}

@media (max-width: 520px) {
  .wb_money_border_div {
    padding: 30px 20px;
  }

  .web_about_wrapper .wb_money_border_div p,
  .web_about_wrapper a {
    font-size: 16px;
  }

  .web_about_wrapper .wb_money_border_div h1 {
    font-size: 26px;
  }
}

/* / padcast 2 css end  / */

/* start payment page  */

.web_form-wrapper {
  padding: 95px 0px 60px 0px;
}

.web_form-wrapper p {
  margin-bottom: 0px;
}

.web_form-wrapper h1 {
  padding: 0px 35px !important;
}

.web_form-wrapper .form-control,
.web_slider .form-control {
  height: 46px;
  border: 1px solid #000;
  box-shadow: 0px 5px 5px rgb(0 0 0 /3%);
  border-radius: 5px;
}

.web_form-wrapper .form-control::placeholder {
  color: #000000;
}

.web_form-wrapper .form-control:focus {
  box-shadow: none;
  border: 1px solid #000;
}

.web_form-wrapper button,
.program-form-submit button {
  border: none;
  background: #000000;
  color: #c19d5f;
  padding: 6px 20px;
  border-radius: 5px;
}

.web_border-top-div {
  border-top: 1px solid #000000;
  margin: 15px 0px;
}

.web_form-wrapper h2 {
  font-size: 16px;
}

.web_form-wrapper a {
  color: #000000;
}

.web_mt-20 {
  margin-top: 20px;
}

@media (max-width: 820px) {
  .web_form-wrapper {
    padding: 60px 0px 60px 0px;
  }

  .web_form-wrapper h1 {
    padding: 0px 0px;
  }
}

@media (max-width: 768px) {
  .web_form-wrapper {
    padding: 40px 0px 40px 0px;
  }
}

/* start  event page */

.web_master-classes {
  margin-top: 96px;
}

.web_master-classes h1 {
  margin-bottom: 25px;
}

.web_master-classes h2 {
  font-size: 16px;
}

.web_master-classes p {
  margin-bottom: 0px;
}

.web_master-classes .learn-btn button {
  background: #c19d5f;
  color: #000;
  border: none;
  padding: 7px 24px;
  margin-top: 29px;
  border-radius: 5px;
}

.web_mt-76 {
  margin-top: 76px !important;
}

.web_mb-76 {
  margin-bottom: 76px !important;
}

/* start general testimonial slider  */
.web_slider {
  background: #000;
  margin-top: 96px;
  padding: 50px 0px 0;
  color: #c19d5f;
}

.remove-web-slider-space .web_slider {
  margin-top: 0;
}

.web_slider h1 {
  font-size: 25px !important;
  margin-bottom: 36px;
  color: #c19d5f;
}

.web_slider a {
  color: #c19d5f;
}

.web_slider .carousel-indicators {
  position: relative;
  display: flex;
  align-items: center;
}

/* .carousel-indicators */
.web_slider .carousel-indicators .indicators {
  background: rgb(131, 130, 130);
  height: 11px;
  width: 11px;
  border: 1px solid rgb(131, 130, 130);
  margin: 0px 3px;
}

.web_slider .carousel-indicators .active {
  background: #c19d5f;
  border: 1px solid #c19d5f;
}

.web_more-link a {
  text-decoration: none;
  font-size: 15px;
}

@media (max-width: 820px) {
  .mt-96,
  .mt-76,
  .web_slider {
    margin-top: 50px;
  }

  .web_slider {
    padding: 30px 0px;
  }

  .web_master-classes {
    margin-top: 40px;
  }
}
/* .form-control {
  color: #000 !important;
} */

@media (max-width: 540px) {
  .web_slider .web_slider {
    text-align: center;
  }

  .web_slider {
    margin-top: 50px;
    padding: 20px 0px;
  }

  .web_more-link {
    display: none;
  }
}

/* end event page  */
/* end general testimonial slider  */

.wb_program-inner {
  position: absolute;
  color: #fff;
  bottom: 25px;
  left: 34px;
  font-weight: normal;
}
.web_about_wrapper .wb_program-inner p {
  margin: 0px;
  line-height: 28px;
  color: #fff !important;
}

/* start about page  */
.about-wrapper {
  padding-top: 96px;
}

.about-wrapper h2 {
  text-align: center;
}

.about-wrapper p {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.about-wrapper h3 {
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
}

.about-wrapper .black-border {
  background: #000000;
  content: "";
  display: block;
  width: 30px;
  height: 5px;
  margin: 15px 0px;
}

@media (max-width: 820px) {
  .about-wrapper {
    padding-top: 50px;
  }
}

@media (max-width: 540px) {
  .about-wrapper {
    padding-top: 30px;
  }
}

/* video-wrapper  */
.video-wrapper {
  padding-top: 76px;
}

.video-wrapper h2 {
  margin-bottom: 76px;
}

@media (max-width: 540px) {
  h2 {
    font-size: 24px;
  }
  .web_ready-section h1,
  .web_life-wrapper h1,
  .web_included_wrapper h1 {
    font-size: 22px !important;
  }
}

.wb_time_wrapper p {
  font-size: 20px;
  margin-bottom: 10px;
}

.wb_time_wrapper h1 {
  margin-bottom: 20px;
}

.wb_time_wrapper .wb_outer-div {
  border: 1px solid rgb(109, 108, 108);
  position: relative;
}
.wb_position-div {
  position: absolute;
  right: 25px;
  top: 5px;
}
.wb_time_wrapper .wb_black-div p {
  color: #fff;
}
.wb_time_wrapper .wb_black-div {
  background-color: #000;
  color: #fff;
  padding: 5px 30px;
}

.wb_time_wrapper p {
  margin-bottom: 0px;
  color: #000;
}
.wb_time_wrapper p,
.wb_time_wrapper h1 {
  color: #000;
}

.wb_time_wrapper .wb_inner-div {
  padding: 30px 25px;
}
.web_program-wrapper .wb_text-size {
  color: #fff;
  text-align: center;
}
.web_program-wrapper .wb_text-size p {
  font-size: 20px;
  margin-top: 35px;
  margin-bottom: 96px;
}

.wb_icon-imge {
  position: absolute;
  top: 80px;
  left: 18%;
}

@media (max-width: 1024px) {
  .wb_icon-imge {
    top: 142px;
  }
}

@media (max-width: 820px) {
  .wb_time_wrapper p {
    font-size: 17px;
  }
  .wb_icon-imge {
    top: 80px;
    left: 8%;
  }
}

/* start footer  */
.web_footer {
  background: #000;
  padding: 50px 0px;
}
.web_footer button {
  background: transparent;
  color: #c19d5f;
  border: none;
}

.web_footer .wb_foooter_ul {
  display: flex;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.web_footer .wb_foooter_ul li {
  padding: 0px 15px 0px;
}

/* start dropdown  */
.web_header .dropdown-menu,
.web_footer .dropdown-menu {
  background: rgba(0, 0, 0, 0.6);
}

.web_header .dropdown-item:active,
.web_header .dropdown-item:hover,
.web_footer .dropdown-item:active,
.web_footer .dropdown-item:hover {
  color: #fff;
  background: transparent;
}
.web_header .dropdown-item,
.web_footer .dropdown-item {
  color: #c19d5f;
  padding: 13px;
}

.web_header .dropdown-toggle::after,
.web_footer .dropdown-toggle::after {
  display: none;
}
/* end dropdaown  */

.web_footer a {
  color: #c19d5f;
  text-decoration: none;
}
.web_footer div {
  color: #c19d5f;
  text-decoration: none;
}

.web_footer .fa,
.wb_life_changes {
  font-weight: 400;
  font-size: 16px;
}

.web_footer .footer-btn button {
  border-radius: 3px;
  padding: 3px 10px;
  border: 1px solid #c19d5f;
  margin-left: 15px;
}

.web_footer .footer-btn button {
  background: transparent;
  color: #c19d5f;
}

.web_footer .footer-btn button:hover {
  background: #c19d5f;
  color: #000000;
}

.web_money-btn button {
  border: none;
  background: #c19d5f;
  color: #000;
  padding: 5px 24px;
  border-radius: 3px;
}
.web_slider .wb_position {
  right: 0px;
  bottom: 0px;
}

@media (max-width: 820px) {
  .web_episode-btn {
    margin-bottom: 40px;
  }

  .web_footer {
    text-align: center;
    padding: 55px 0px;
    font-size: 13px;
  }

  .web_success-section .web_episode-btn {
    margin-top: 40px;
  }
}

@media (max-width: 425px) {
  .web_footer .footer {
    padding: 30px 0px;
  }

  .web_footer .footer ul li {
    padding: 0px 10px 0px;
  }
  .web_header .dropdown-item,
  .web_footer .dropdown-item {
    color: #c19d5f;
    padding: 10px 10px 0;
    cursor: pointer;
  }
}

/* end footer  */

@media (max-width: 820px) {
  .web-login-button {
    margin-left: 20px;
  }
  .set-image-center {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 540px) {
  .web_hero-section {
    padding: 74px 0px;
  }
  .web_about_wrapper {
    padding-top: 30px;
  }
  .web_about_wrapper h2 {
    font-size: 24px;
    text-align: center;
  }
  .web_awareness_wrapper {
    padding: 40px 0px 40px 0px;
  }
  .web_footer .wb_foooter_ul li {
    padding: 0px 5px 0px;
  }
  .wb_icon-imge {
    display: none;
  }
}

/* start comunity page  */
.web_pb-96 {
  padding-bottom: 96px;
}

.web_about_wrapper .text-start h2 {
  text-align: left;
}

@media (max-width: 820px) {
  .web_pb-96 {
    padding-bottom: 40px;
  }

  .web_read-btn {
    margin-top: 40px;
  }
}

/* end comunity page  */

/* / start media page / */

.media-banner h2 {
  font-weight: 600;
}

.pt-80 {
  padding-top: 80px;
}

.web_ready-section h4 {
  font-size: 18px;

  margin-top: 30px;
}

.web_ready-section p {
  margin-top: 0px;
}

.border-top-div {
  border-top: 5px solid #000;
  margin-top: 60px;
}
.media-people h4 {
  color: #000;
}

.media-people a {
  text-decoration: none;
}

.pb-96 {
  padding-bottom: 96px;
}
.media-page .web_included_wrapper {
  margin-top: 96px;
  background: #eaeaea;
  padding: 96px 0px;
}
.media-page .web_included_wrapper p {
  margin-bottom: 0;
}
@media (max-width: 920px) {
  .pt-80 {
    padding-top: 40px;
  }
  .pb-96 {
    padding-bottom: 54px;
  }
  .wb_life_changes h3 {
    text-align: center;
    font-size: 20px;
  }
}

.event-slider .slick-prev:before,
.event-slider .slick-next:before {
  color: #000 !important;
}

/* / term- and privacy policy page /  */

.wb_term-wrapper {
  padding: 96px 0px;
  font-size: 18px;
  line-height: 24px;
}
.wb_term-wrapper .wb_list {
  list-style-type: disc;
}
.wb_term-wrapper li {
  padding-left: 20px;
}
.wb_term-wrapper span {
  font-weight: 600;
}
.wb_term-wrapper p,
.wb_term-wrapper li {
  margin-top: 20px;
}

.privacy-policy-page p,
.privacy-policy-page li {
  color: #231f20 !important;
}

.privacy-policy-box {
  color: #231f20;
  text-align: center;
  /* font-size: 17px; */
  display: flex;
  justify-content: center;
  background-color: #c19d5f;
}
.privacy-policy-box p {
  margin-bottom: 0;
}
.privacy-policy-box a {
  color: #231f20;
  text-decoration: none;
}
.terms-link:hover {
  cursor: pointer;
}

.wb_term-wrapper a {
  color: #000;
}

@media (max-width: 912px) {
  .wb_term-wrapper {
    padding: 50px 0px;
  }
}
@media (max-width: 540px) {
  .wb_term-wrapper {
    padding: 30px 0px;
  }
  .privacy-policy-box {
    flex-direction: column;
  }
  .web_header .navbar-brand {
    display: none !important;
  }
}

.podcast-form .form-control {
  padding: 13px 21px !important;
  border: 1px solid #000 !important;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 3%) !important;
  border-radius: 5px !important;
  color: #231f20 !important;
}
.form-control {
  -webkit-appearance: auto !important; /* Safari */
  -ms-appearance: auto !important; /* IE 10 and IE 11 */
  appearance: auto !important; /* Standard syntax */
}

/* -------------new
  styling
  ---------------------
  /
  mastermind
  page
  / */

.wb_time_wrapper .wb_f-size p {
  font-size: 16px;
}
.wb_bg-color-white {
  background: #fff;
  padding: 40px !important;
  border-radius: 10px;
  margin-bottom: 60px;
}
.wb_para-color {
  color: #fff;
  text-align: center;
  padding-bottom: 30px;
}

.wb_para-color p {
  font-size: 18px;
  margin-bottom: 0px;
}

.wb_black-div .fa-plus {
  padding: 5px 9px;
  border-radius: 50%;
  background: #000;
  color: #fff;
  font-size: 41px;
}
.wb_black-div h5 {
  font-size: 18px;
}
.wb_black-div a {
  font-size: 13px;
  text-decoration: underline;
}
.wb_black-div,
.wb_black-div a {
  color: #000;
}

.set-visibility-hidden {
  display: none !important;
}

@media (max-width: 912px) {
  .wb_bg-color-white {
    margin-bottom: 0px;
  }
}
@media (max-width: 540px) {
  .wb_bg-color-white {
    padding: 20px;
  }
  .web_included_wrapper .media-image img {
    margin: 0 auto;
  }
}

.podcast_short_description {
  color: #000;
  font-size: 18px;
}

.podcast_detail_description a,
.podcast_detail_description span {
  color: #c19d5f !important;
}

.sticky {
  position: fixed;
  top: 34px;
  width: 100%;
  z-index: 1;
}

.sticky-nav {
  top: 0px;
  margin-top: 0px;
}

.sticky + .web-margin-after-header {
  padding-top: 60px;
}

.event-form-popup {
  position: relative;
}

.event-form-popup span {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 30px;
  width: 30px;
  font-size: 17px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: #c19d5f;
  text-align: center;
  cursor: pointer;
}

.event-form-popup p {
  font-size: 16px;
}

.event-form-popup h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.event-form-popup .web_awareness_wrapper {
  padding: 20px 0;
}

.event-form-popup .podcast-form .form-control {
  padding: 9px 21px !important;
}

.web-events-card img {
  cursor: pointer;
}

@media (max-width: 540px) {
  .event-form-popup p {
    font-size: 14px;
    margin-bottom: 0;
  }

  .event-form-popup h2 {
    font-size: 22px;
  }
  .plan-short-description p {
    margin-bottom: 20px;
  }
}

.success-habits-lower-banner p {
  margin-top: 1rem;
}
.middle-video-section {
  color: #000;
}
.middle-video-section h2 {
  color: #000 !important;
}

.success-habits-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}
